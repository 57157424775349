<template>
  <div id="product">
    <Header />
    <div class="product_content">
      <div class="p_aside">
        <div class="p_aside_title">产品一览</div>
        <div class="menue">
          <el-menu
            background-color="#fff"
            text-color="#121212"
            active-text-color="#45B035"
            :default-active="active_index"
            :unique-opened="true"
            @select="handleSelect"
          >
            <el-submenu index="1">
              <template slot="title">
                <span>无糖茶饮料-让茶</span>
              </template>
              <el-menu-item index="1-1">无糖果茶系列</el-menu-item>            
              <el-menu-item index="1-2">饭后茶系列</el-menu-item>            
              <el-menu-item index="1-3">高山茶系列</el-menu-item>
              
              <!-- <el-menu-item index="1-4">无糖果味茶系列</el-menu-item>  -->
            </el-submenu>
            <!-- <el-submenu index="2"> -->
              <el-menu-item index="1-4">植物轻养水–植与养</el-menu-item>    
              <!-- <el-submenu index="2-0-1">
                <template slot="title">
                  <span>纯味系列</span>
                </template>
                <el-menu-item index="2-1">经典系列</el-menu-item>
                <el-menu-item index="2-2">高山系列</el-menu-item>
              </el-submenu>
              <el-submenu index="2-0-2">
                <template slot="title">
                  <span>调味系列</span>
                </template>
                <el-menu-item index="2-3">果味系列</el-menu-item>
                <el-menu-item index="2-4">花果系列</el-menu-item>
              </el-submenu> -->

              <!-- <el-menu-item index="2-3">果园系列</el-menu-item> -->
              <!-- <el-menu-item index="2-5">汉方养园系列</el-menu-item>
              <el-menu-item index="2-6">组合系列</el-menu-item> -->
            <!-- </el-submenu> -->
          </el-menu>
        </div>
      </div>
      <div class="p_content">
        <router-view></router-view>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "Product",
  components: {
    Header,
    Footer,
  },
  props: {},
  setup() {},
  data() {
    return {
      active_index: "1-1",
    };
  },
  methods: {
    handleSelect(index) {
      this.active_index = index;
      sessionStorage.setItem("active", index);
      let type = index.split("-");
      if (type[0] == 1) {
        this.$router.push(`/product/list/rangcha?h=${type[1]}`);
      } else {
        this.$router.push(`/product/list/tasantea?h=${type[1]}`);
      }
    },
  },
  created() {
    if (sessionStorage.getItem("active")) {
      this.active_index = sessionStorage.getItem("active");
    }
  },
  mounted() {},
  destroyed() {
    sessionStorage.removeItem("active");
  },
};
</script>

<style scoped lang="less">
#product {
  width: 100%;
  min-height: calc(100vh - 113px - 214px);
  background: #e9e9df;
  overflow: hidden;
  @width: 62.5%;
  @min_width: 996px;
  .product_content {
    width: 1200px;
    min-height: 100vh;
    margin: 113px auto 23px auto;
    .p_aside {
      width: 180px;
      /* float: left; */
      position: fixed;
      top: 113px;
      z-index: 998;
      .p_aside_title {
        width: 180px;
        height: 48px;
        background: #45B035;
        line-height: 48px;
        font-size: 22px;
        font-weight: 500;
        color: #ffffff;
        padding-left: 11px;
        box-sizing: border-box;
      }
      .menue {
        & /deep/ .el-menu-item {
          min-width: 180px !important;
        }
      }
    }
    .p_content {
      width: 1006px;
      min-width: calc(@min_width - 180px - 140px);
      /* height: calc(100vh - 113px);
      overflow: scroll; */
      float: left;
      margin-left: 194px;
      background: #e9e9df;
    }
  }
  @media screen and (min-width: 968px) and (max-width: 1100px) {
    .product_content {
      width: 968px;
      .p_content {
        width: 774px;
      }
    }
  }
}
</style>
